import { WalletActivity } from '@/models/wallet/WalletActivity'
import { IWalletUseCase, WalletUseCase } from '@/usecase/wallet/WalletUseCase'
import { GetWalletActivitiesParam } from '@/gateway/wallet/model/GetWalletActivitiesParam'
import { ActivityTableData } from '@/presentation/wallet/model/ActivityTableData'
import { WalletActivityEnum } from '@/enums/WalletActivityEnum'
import { GetRemittanceGroups } from '@/gateway/remittance/GetRemittanceGroups'
import { RemittanceHistoryGroup } from '@/models/remittance'
import i18n from '@/plugins/i18n'
import { CurrencyEnum } from '@/enums/CurrencyEnum'
import dayjs from 'dayjs'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

export interface IWalletActivitiesPresentation {
  walletActivitiesToShow: Array<Array<ActivityTableData>>
  activitiesToShowIndex: number
  remittanceHistory: Array<RemittanceHistoryGroup>

  searchWalletActivities(params: GetWalletActivitiesParam): Promise<void>
  showMore(): Promise<void>
  getTransactionString(transaction: WalletActivityEnum): string
  getTransactionCategoryByEnum(transaction: WalletActivityEnum): string
  getActivityTableTitle(transaction: WalletActivityEnum, remittanceGroupId: string): string
  setActivitiesToShow(activities: Array<WalletActivity>): void
  makeActivityAsTable(activity: WalletActivity): ActivityTableData
  initialize(walletId: number, currency: keyof typeof CurrencyEnum): Promise<string>
  getRecentRemittanceHistory(): Promise<void>
  waitingDepositAsTable(): Array<ActivityTableData>
}

export class WalletActivitiesPresentation implements IWalletActivitiesPresentation {
  public walletActivitiesToShow: Array<Array<ActivityTableData>>
  public activitiesToShowIndex: number
  public remittanceHistory: Array<RemittanceHistoryGroup>

  private useCase: IWalletUseCase
  private currentPage: number
  private walletId: number
  private corpId: number = Number(MemberInfoGateway.getMemberInfo().corp_id)
  private transactionStatus: Array<WalletActivityEnum> = []
  private currency: keyof typeof CurrencyEnum

  /**
   * WalletActivityPresentation의 생성자
   *
   * - useCase에 WalletUseCase 객체를 생성 할당
   * - currentPage, walletActivitiesToShow 등의 값 초기화
   * - walletId는 존재할 수 없는 임의의 음수 값으로 할당. initialize에서 받아 설정
   */
  constructor() {
    this.useCase = new WalletUseCase()
    this.currentPage = 1
    this.walletId = -1
    this.activitiesToShowIndex = 0
    this.walletActivitiesToShow = [[]]
    this.remittanceHistory = []
    this.currency = 'KRW'
  }

  /**
   * params 파라미터로 walletActivities를 가져와 walletActivitiesToShow에 할당한다.
   *
   * WalletView에서 필터를 초기화했을 때 실행되는 함수로 currentPage, walletActivitiesToShow 등을 초기화
   *
   * @param {GetWalletActivitiesParam} params walletActivities를 가져올 파라미터
   */
  async searchWalletActivities(params: GetWalletActivitiesParam): Promise<void> {
    if (params.transaction) this.transactionStatus = params.transaction
    const walletActivities: Array<WalletActivity> = await this.useCase.getWalletActivities(params)
    this.walletActivitiesToShow = [[]]
    this.activitiesToShowIndex = 0
    this.currentPage = 1
    this.setActivitiesToShow(walletActivities)
  }

  /**
   * currentPage 다음 페이지의 walletActivities를 API로 가져와 walletActivitiesToShow에 할당한다.
   */
  async showMore(): Promise<void> {
    const activitiesMore: Array<WalletActivity> = await this.useCase.getWalletActivities({
      page: ++this.currentPage,
      wallet_id: this.walletId,
      corp_id: this.corpId,
      transaction: this.transactionStatus
    })
    this.setActivitiesToShow(activitiesMore)
  }

  /**
   * activities를 연, 월별로 분류하여 walletActivitiesToShow에 할당한다.
   *
   * - walletActivitiesToShow가 비어있다면 walletActivitiesToShow[0]에 테이블 삽입
   * - 그렇지 않을 경우, 현재 activitiesToShowIndex에 삽입된 데이터의 연, 월과 삽입하려는 activity의 연, 월 비교
   * - 같다면 현재 activitiesToShowIndex에 삽입, 다르다면 activitiesToShowIndex를 증가시키고 삽입
   *
   * @param {Array<WalletActivity>} activities 할당할 activities
   */
  setActivitiesToShow(activities: Array<WalletActivity>): void {
    if (activities.length <= 0) return

    activities.forEach(activity => {
      if (this.walletActivitiesToShow[0].length < 1) {
        this.walletActivitiesToShow[0].push(this.makeActivityAsTable(activity))
      } else {
        if (
          `${this.walletActivitiesToShow[this.activitiesToShowIndex][0].transaction_time}`.slice(0, 7) ===
          `${activity.local_transaction_time}`.slice(0, 7)
        ) {
          this.walletActivitiesToShow[this.activitiesToShowIndex].push(this.makeActivityAsTable(activity))
        } else {
          this.walletActivitiesToShow[++this.activitiesToShowIndex] = []
          this.walletActivitiesToShow[this.activitiesToShowIndex].push(this.makeActivityAsTable(activity))
        }
      }
    })
  }

  /**
   * transaction 값에 따라 tableTitle을 리턴한다.
   *
   * - WITHDRAW_BY_REMITTANCE일 경우
   *   - 송금그룹 ID가 없다면 '송금 요청 건'
   *   - 송금그룹 ID가 있다면 '송금 요청 건: 송금그룹 ID'
   * - WITHDRAW_BY_WALLET_REFUND일 경우 '{currency} 월렛에서 출금 완료'
   * - DEPOSIT_BY_ADMIN일 경우 '{currency} 월렛에 입금 완료 (송금 실패로 환불됨)'
   * - DEPOSIT_BY_AUTO_DEBIT, DEPOSIT_BY_PAYMENT, DEPOSIT_BY_PROMOTION, DEPOSIT_BY_SYSTEM, DEPOSIT_BY_VIRTUAL_ACCOUNT일 경우 '{currency} 월렛에 입금 완료'
   * - 그 외의 경우 빈 string
   *
   * @param {WalletActivityEnum} transaction 계산한 transaction(상태)
   * @param {string} remittanceGroupId 송금그룹 ID
   * @returns 계산한 title
   */
  getActivityTableTitle(transaction: WalletActivityEnum, remittanceGroupId: string): string {
    switch (transaction) {
      case WalletActivityEnum.WITHDRAW_BY_REMITTANCE:
        return remittanceGroupId === ''
          ? `${i18n.t('wallet.activity.remittance')}`.split(':')[0]
          : `${i18n.t('wallet.activity.remittance', { transfer_group: remittanceGroupId })}`
      case WalletActivityEnum.WITHDRAW_BY_WALLET_REFUND:
        return `${i18n.t('wallet.activity.withdrawal', { currency: this.currency })}`
      case WalletActivityEnum.DEPOSIT_BY_ADMIN:
        return `${i18n.t('wallet.activity.deposit', { currency: this.currency })} ${i18n.t(
          'wallet.status.deposit_refund'
        )}`
      case WalletActivityEnum.DEPOSIT_BY_AUTO_DEBIT:
      case WalletActivityEnum.DEPOSIT_BY_PAYMENT:
      case WalletActivityEnum.DEPOSIT_BY_PROMOTION:
      case WalletActivityEnum.DEPOSIT_BY_SYSTEM:
      case WalletActivityEnum.DEPOSIT_BY_VIRTUAL_ACCOUNT:
        return `${i18n.t('wallet.activity.deposit', { currency: this.currency })}`
      default:
        return ''
    }
  }

  /**
   * WalletActivity를 ActivityTableData 형태로 변환한다.
   *
   * @param {WalletActivity} activity 변환할 activity
   * @returns {ActivityTableData} 변환한 tableData
   */
  makeActivityAsTable(activity: WalletActivity): ActivityTableData {
    const transactionTimeString = `${activity.local_transaction_time}`
    return {
      transaction: activity.transaction,
      transaction_tag_label: this.getTransactionString(activity.transaction),
      transaction_time: `${transactionTimeString.slice(0, 10)}, ${transactionTimeString.slice(11, 16)}`,
      title: this.getActivityTableTitle(activity.transaction, activity.remittance_group_code),
      remittance_group_id: activity.remittance_group_id,
      transaction_amount: activity.transaction_amount.toLocaleString()
    }
  }

  /**
   * transaction 상태에 맞는 category를 리턴한다.
   *
   * @param {WalletActivityEnum} transaction category를 계산할 상태
   * @returns {string} 계산된 category
   */
  getTransactionCategoryByEnum(transaction: WalletActivityEnum): string {
    switch (transaction) {
      case WalletActivityEnum.WITHDRAW_BY_REMITTANCE:
        return 'Remittance'
      case WalletActivityEnum.WITHDRAW_BY_WALLET_REFUND:
        return 'Withdrawal'
      case WalletActivityEnum.DEPOSIT_BY_ADMIN:
      case WalletActivityEnum.DEPOSIT_BY_AUTO_DEBIT:
      case WalletActivityEnum.DEPOSIT_BY_PAYMENT:
      case WalletActivityEnum.DEPOSIT_BY_PROMOTION:
      case WalletActivityEnum.DEPOSIT_BY_SYSTEM:
      case WalletActivityEnum.DEPOSIT_BY_VIRTUAL_ACCOUNT:
        return 'Deposit'
      default:
        return ''
    }
  }

  /**
   * Tag에 들어갈 레이블을 transaction에 맞게 리턴한다.
   *
   * @param {WalletActivityEnum} transaction 월렛 거래 상태값
   * @returns 계산한 레이블
   */
  getTransactionString(transaction: WalletActivityEnum): string {
    const transactionLabel = this.getTransactionCategoryByEnum(transaction).toLowerCase()
    const label = `${i18n.t(`wallet.activity.${transactionLabel}_label`)}`
    return label.includes('wallet.activity.') ? '' : label
  }

  /**
   * 입금 대기중 상태를 찾기 위해 최신 송금 내역을 가져와 remittanceHistory에 할당한다.
   */
  async getRecentRemittanceHistory(): Promise<void> {
    const data = {
      corp_id: this.corpId,
      page: 1,
      unit: 1
    }
    const requestParam = Object.assign({}, data)
    const response: any = await GetRemittanceGroups.getInstance().request(requestParam)

    this.remittanceHistory = response.data.list as Array<RemittanceHistoryGroup>
  }

  /**
   * 최신 송금 내역중 group_status가 DEPOSIT_WAIT_STATUS_CODE인 송금 내역이 있다면 이를 ActivityTableData 형태로 리턴한다.
   *
   * @returns {Array<ActivityTableData>} 송금 대기중인 ActivityTableData 배열
   */
  waitingDepositAsTable(): Array<ActivityTableData> {
    const DEPOSIT_WAIT_STATUS_CODE = 1
    const waitingDeposit = this.remittanceHistory.filter(item => item.group_status === DEPOSIT_WAIT_STATUS_CODE)

    if (waitingDeposit.length > 0) {
      return [
        {
          transaction: WalletActivityEnum.WITHDRAW_BY_REMITTANCE,
          transaction_tag_label: `${i18n.t('wallet.filter.remittance')}`,
          transaction_time: `${dayjs(new Date(waitingDeposit[0].created_at)).format('YYYY-MM-DD, HH:mm')}`,
          title: `${i18n.t('wallet.activity.remittance', { transfer_group: waitingDeposit[0].code })}`,
          transaction_amount: waitingDeposit[0].total_amount.toLocaleString(),
          remittance_group_id: waitingDeposit[0].id,
          isWaitingDeposit: true
        }
      ]
    }
    return []
  }

  /**
   * walletId, currency등을 받아 WalletActivitiesPresentation의 변수에 각각 할당하고, 1 페이지에 대한 walletActivities를 가져와 walletActivitiesToShow에 할당한다.
   *
   * @param {number} walletId WalletView에서 표현되는 wallet의 id
   * @param {keyof typeof CurrencyEnum} currency WalletView에서 표현되는 currency
   * @returns {Promise<string>} walletActivities가 존재할 경우 첫 activity의 transaction_time string
   */
  async initialize(walletId: number, currency: keyof typeof CurrencyEnum): Promise<string> {
    const getWalletActivitiesParam: GetWalletActivitiesParam = {
      page: 1,
      wallet_id: walletId,
      corp_id: this.corpId
    }
    this.walletId = walletId
    const walletActivities: Array<WalletActivity> = await this.useCase.getWalletActivities(getWalletActivitiesParam)
    this.setActivitiesToShow(walletActivities)
    this.currency = currency

    return walletActivities.length > 0 ? `${walletActivities[0].transaction_time}` : ''
  }
}
