
import Vue from 'vue'
import {
  WalletActivitiesPresentation,
  IWalletActivitiesPresentation
} from '@/presentation/wallet/WalletActivitiesPresentation'
import Component from 'vue-class-component'
import { IWalletPresentation, WalletPresentation } from '@/presentation/wallet/WalletPresentation'
import { CreatePaymentTransactionResponse } from '@/gateway/wallet/model/CreatePaymentTransactionResponse'
import { CurrencyEnum } from '@/enums/CurrencyEnum'
import { WalletActivityEnum } from '@/enums/WalletActivityEnum'
import WalletInfoView from '@/components/wallet/WalletInfoView.vue'
import { Wallet } from '@/models/wallet/Wallet'
import WalletActivityTable from '@/components/wallet/WalletActivityTable.vue'
import { ActivityTableData } from '@/presentation/wallet/model/ActivityTableData'
import DepositModal from '@/components/wallet/DepositModal.vue'
import { Mutation } from 'vuex-class'
import { Prop, Watch } from 'vue-property-decorator'
import { WalletListStatus } from '@/enums/WalletListStatus'
import { MemberInfoGateway } from '@/gateway/commons/MemberInfoGateway'

@Component({
  components: { DepositModal, WalletInfoView, WalletActivityTable }
})
export default class WalletView extends Vue {
  @Mutation setNewWalletActivityDot: any
  @Prop() private elMainScrollTop!: number

  corpId: number = Number(MemberInfoGateway.getMemberInfo().corp_id)
  walletId!: number
  currency!: keyof typeof CurrencyEnum
  wallet: Wallet = {
    id: -1,
    corp_id: '',
    currency: 'KRW',
    balance: 0
  }

  finishedInitialize: boolean = false
  activityPresentation: IWalletActivitiesPresentation = new WalletActivitiesPresentation()
  walletPresentation: IWalletPresentation = new WalletPresentation()

  walletActivitiesToShow: Array<Array<ActivityTableData>> = []
  filterParam: Array<WalletActivityEnum> = []

  waitingDepositTable: Array<ActivityTableData> = []

  isScrolled: boolean = false
  seeMore: boolean = true
  isEmptyActivities: boolean = true

  depositResponseToShow: CreatePaymentTransactionResponse = {
    payment_method_information: {
      account_number: '',
      account_holder_name: '',
      bank_code: ''
    }
  }

  depositDialog: boolean = false

  /**
   * 화면의 '필터' 셀렉트가 변경됐을 때 filter parameter를 셀렉트에서 선택된 값으로 설정하고 1페이지로 walletActivities를 검색한다.
   *
   * @param {Array<WalletActivityEnum>} filterParam 필터 셀렉트에서 선택된 값
   */
  filterSet(filterParam: Array<WalletActivityEnum>): void {
    this.filterParam = filterParam
    this.searchWalletActivities(1)
  }

  /**
   * page parameter로 walletActivities를 검색하여 walletActivitiesToShow에 할당하고 화면 스크롤을 최상단으로 이동한다.
   *
   * @param {number} page 검색할 페이지
   *
   * @async
   */
  async searchWalletActivities(page?: number): Promise<void> {
    await this.activityPresentation.searchWalletActivities({
      page: page,
      wallet_id: this.walletId,
      corp_id: this.corpId,
      transaction: this.filterParam
    })
    this.walletActivitiesToShow = []
    this.walletActivitiesToShow = this.activityPresentation.walletActivitiesToShow.slice(0)
    this.$el.parentElement?.scrollTo(0, 0)
  }

  /**
   * 'show-more-button'을 눌렀을 때 월렛의 현재 각 상태별로 액션을 실행한다.
   *
   * - 월렛 거래 내역이 없거나 '입금' 필터로 검색했을 때 검색 결과가 없을 경우 입금하기 모달을 띄운다. (이 때 버튼의 레이블은 '입급하기')
   * - '송금' 필터로 검색했을 때 검색 결과가 없을 경우 '송금하기' 페이지로 넘어간다. (이 때 버튼의 레이블은 '송금하기')
   * - 결과가 정상적으로 존재하거나 이외의 경우 클릭 당시의 스크롤 위치를 기억한 후, 추가 activity를 가져온 뒤 기억한 포지션으로 스크롤을 이동시킨다. (테이블이 추가됐을 때 스크롤이 최하단에 고정되는 것을 수정하기 위함)
   *
   * @async
   */
  async showMoreActivities(): Promise<void> {
    switch (this.emptyStatus) {
      case WalletListStatus.NO_HISTORY:
      case WalletListStatus.NO_DEPOSIT:
        this.openDepositDialog()
        break
      case WalletListStatus.NO_REMITTANCE:
        this.$router.push('/transfer/selectRecipients')
        break
      case WalletListStatus.FILLED:
      default:
        const thisScrollTop = this.elMainScrollTop
        await this.activityPresentation.showMore()
        this.walletActivitiesToShow = this.activityPresentation.walletActivitiesToShow.slice(0)
        this.$el.parentElement?.scrollTo(0, thisScrollTop)
    }
  }

  /**
   * 입금하기 모달을 연다.
   */
  openDepositDialog(): void {
    this.depositDialog = true
  }

  /**
   * 입금하기 모달을 닫는다.
   */
  closeDepositDialog(): void {
    this.depositDialog = false
  }

  /**
   * 입금 계좌정보 관련 정보를 가져와 depositResponseToShow에 할당한다.
   *
   * @param {keyof typeof CurrencyEnum} currency 조회할 환 종류. 현재는 KRW만 지원
   */
  async doDeposit(currency: keyof typeof CurrencyEnum): Promise<void> {
    this.depositResponseToShow = await this.walletPresentation.createPaymentTransaction(currency, this.walletId)
  }

  /**
   * 가장 최신 wallet activity의 transaction_time을 받아 localStorage latestWalletStamp 키에 저장한 후 state.walletActivityDot을 false로 설정한다.
   * (방금 월렛 화면에 접속하여 dot이 필요하지 않으므로)
   *
   * @param {string} latestWalletStamp 조회된 activity중 가장 최신 것의 transaction_time
   */
  setWalletStamp(latestWalletStamp: string) {
    if (latestWalletStamp === 'empty') return
    localStorage.setItem('latestWalletStamp', latestWalletStamp)
    this.setNewWalletActivityDot(0)
  }

  /**
   * walletPresentation과 activityPresentation의 initialize를 호출하고, wallet, walletId등의 변수를 적절한 값으로 초기화한다.
   *
   * activityPresentation initialize의 리턴 값인 가장 최신 transaction_time을 받아 setWalletStamp 함수를 호출하고
   * walletActivitiesToShow, waitingDepositTable 등에 할당될 값을 API로부터 받아와 할당한다.
   * @async
   */
  async initializePresentation() {
    await this.walletPresentation.initialize()
    this.wallet = this.walletPresentation.getWalletByCurrency(
      this.$route.params.currency as keyof typeof CurrencyEnum
    ) as Wallet
    this.walletId = this.wallet.id
    this.currency = this.$route.params.currency as keyof typeof CurrencyEnum
    const latestWalletStamp: string = await this.activityPresentation.initialize(this.walletId, this.currency)
    this.setWalletStamp(latestWalletStamp)
    if (this.currency === 'KRW') {
      await this.doDeposit(this.currency)
    }
    this.walletActivitiesToShow = this.activityPresentation.walletActivitiesToShow.slice(0)
    await this.activityPresentation.getRecentRemittanceHistory()
    this.waitingDepositTable = this.activityPresentation.waitingDepositAsTable()
  }

  /**
   * props의 스크롤 최상단값이 바뀔 때마다, 최상단에서 조금이라도 하단으로 스크롤됐는지 판단하여 스크롤 된 상태일 경우 isScrolled에 true를 할당한다.
   */
  @Watch('elMainScrollTop')
  watchScroll() {
    this.isScrolled = this.elMainScrollTop > 0
  }

  /**
   * walletActivitiesToShow에 내역이 있는지를 나타낸다. 있으면 true
   *
   * @type {boolean}
   */
  get doesHaveActivities(): boolean {
    return this.walletActivitiesToShow[0].length > 0
  }

  /**
   * emptyStatus에 따른 하단 버튼 label을 리턴한다.
   *
   * @type {string}
   */
  get belowBtnString(): string {
    switch (this.emptyStatus) {
      case WalletListStatus.NO_HISTORY:
      case WalletListStatus.NO_DEPOSIT:
        return `${this.$t('wallet.main.deposit')}`
      case WalletListStatus.NO_REMITTANCE:
        return `${this.$t('wallet.main.send')}`
      case WalletListStatus.FILLED:
      default:
        return `+ ${this.$t('wallet.main.see_more')}`
    }
  }

  /**
   * 월렛 조회 필터 및 결과에 따른 현재 월렛 리스트의 상태를 리턴한다.
   *
   * @type {WalletListStatus}
   */
  get emptyStatus(): WalletListStatus {
    if (this.walletActivitiesToShow[0].length > 0) return WalletListStatus.FILLED
    if (this.isEmptyActivities) return WalletListStatus.NO_HISTORY
    else {
      if (this.activityPresentation.getTransactionCategoryByEnum(this.filterParam[0]) === 'Deposit')
        return WalletListStatus.NO_DEPOSIT
      else if (this.activityPresentation.getTransactionCategoryByEnum(this.filterParam[0]) === 'Remittance')
        return WalletListStatus.NO_REMITTANCE
      else return WalletListStatus.FILLED
    }
  }

  /**
   * 나타낼 월렛 리스트가 존재하지 않을 때, 월렛 리스트 상태에 따른 안내 문구를 나타낸다.
   *
   * @type {string}
   */
  get noActivityString(): string {
    if (this.emptyStatus === WalletListStatus.NO_REMITTANCE) return `${this.$t('wallet.main.no_remittance')}`
    return `${this.$t('wallet.main.empty')}`
  }

  /**
   * initializePresentation을 실행하여 관련 변수를 초기화하고, 초기화 완료 시 finishedInitialize를 true로 변환한다.
   *
   * finishedInitialize가 true일 때만 WalletView의 default-layout이 렌더된다.
   */
  async created() {
    await this.initializePresentation()
    if (this.doesHaveActivities) this.isEmptyActivities = false
    this.finishedInitialize = true
  }
}
