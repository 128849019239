
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Wallet } from '@/models/wallet/Wallet'
import { CreatePaymentTransactionResponse } from '@/gateway/wallet/model/CreatePaymentTransactionResponse'
import { WalletActivityEnum } from '@/enums/WalletActivityEnum'

@Component
export default class WalletInfoView extends Vue {
  @Prop() readonly wallet!: Wallet;
  @Prop() readonly depositResponse!: CreatePaymentTransactionResponse;

  filterOptions: Array<{ value: string, label: string }> = []

  filterValue: string = ''

  isSelected: boolean = false

  onChangeSelect (value: string) {
    this.isSelected = true
    this.filterValue = value
    let filterParam: Array<WalletActivityEnum> = []
    switch (value) {
      case 'showAll':
        filterParam = []
        break
      case 'deposit':
        filterParam = [
          WalletActivityEnum.DEPOSIT_BY_VIRTUAL_ACCOUNT,
          WalletActivityEnum.DEPOSIT_BY_AUTO_DEBIT,
          WalletActivityEnum.DEPOSIT_BY_ADMIN,
          WalletActivityEnum.DEPOSIT_BY_PROMOTION,
          WalletActivityEnum.DEPOSIT_BY_SYSTEM,
          WalletActivityEnum.DEPOSIT_BY_PAYMENT
        ]
        break
      case 'remittance':
        filterParam = [
          WalletActivityEnum.WITHDRAW_BY_REMITTANCE
        ]
        break
      default:
        break
    }

    this.$emit('filterSet', filterParam)
  }

  getBankName (bankNumber: string): string {
    if (bankNumber === '050') return `${this.$t('commons.mutual_bank_name')}`
    else return ''
  }

  doDeposit (): void {
    this.$emit('openDepositDialog')
  }

  doSend (): void {
    this.$router.push('/transfer/selectRecipients')
  }

  setFilterOptions () {
    this.filterOptions = [
      {
        value: 'showAll',
        label: `${this.$t('wallet.filter.all')}`
      },
      {
        value: 'deposit',
        label: `${this.$t('wallet.filter.deposits')}`
      },
      {
        value: 'remittance',
        label: `${this.$t('wallet.filter.remittance')}`
      }
    ]
  }

  created () {
    this.setFilterOptions()
  }
}
